<template>
  <div class="w-block__footer">
    <!-- footer block -->
    Bloc footer à personnaliser (Par exemple : insérer le logo client).

    <exc-dialog />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ExcDialog from '~/excentrics/components/ExcDialog'
export default {
  components: {
    ExcDialog,
  },
  computed: {
    ...mapGetters({
      isPersistantVisible: 'player/isPersistantVisible',
    }),
  },
  methods: {
    handleOpen(url) {
      window.open(url, '_blank')
    },
  },
}
</script>
<style lang="scss" scoped>
.list-footer-images {
  display: flex !important;
  align-items: center;
  margin-top: 1em;
  flex-wrap: wrap !important;
}
</style>
